import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import { useDarkMode } from './useDarkMode';
import { FaMoon, FaSun } from 'react-icons/fa';
import { Helmet } from 'react-helmet';


function App() {
  

  const [theme, toggleTheme] = useDarkMode(); // Use the custom hook to manage dark/light mode

  const handleToggleClick = (e) => {
    // Toggle the theme
    toggleTheme();
    
    // Trigger the wave animation
    const button = e.currentTarget;
    button.classList.add('wave-animation');
    
    // Remove the animation class after it finishes
    setTimeout(() => button.classList.remove('wave-animation'), 600); // Match the duration with the CSS animation
  };
  return (
    <Router>
    <Helmet>
            

        <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WHNLMDJ4');`}

        </script>

       
    </Helmet>
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHNLMDJ4"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
     {/* Toggle Button for Dark/Light Mode */}
     <button aria-label="Toggle dark mode"
        className="theme-toggle-button" 
        onClick={handleToggleClick}
      >
        {theme === 'dark' ? <FaSun className="sun-icon" /> : <FaMoon className="moon-icon" />}
      </button>
      <Footer/>
    </Router>
    
  );
}

export default App;
