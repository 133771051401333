import React from 'react';
import '../assets/css/Footer.css'; // We'll create this CSS file next
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import logofooter from '../assets/images/logo-footer.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <a href='/'><img src={logofooter} alt="Footer logo with Quillnext contact information and social media links" className="footer-logo" /></a>
      </div>
      <div className="footer-middle">
        <ul className="footer-links">
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
        <ul className="footer-links">
          <li><a href="https://www.facebook.com/quillnext" target="_blank" aria-label="Follow Us on Facebook" rel="noopener noreferrer"><FaFacebook /></a></li>
          <li><a href="https://www.linkedin.com/company/quillnext/" target="_blank" aria-label="Follow Us on Linkedin" rel="noopener noreferrer"><FaLinkedin /></a></li>
          <li><a href="https://www.instagram.com/quill_next/" target="_blank" aria-label="Follow Us on Instagram" rel="noopener noreferrer"><FaInstagram /></a></li>
        </ul>
      </div>
      <div className="footer-contact">
        <p>Mobile: <a href='tel:918318922064'>+91 8318922064</a></p>
        <p>Email: <a href='mailto:info@quillnext.com'>info@quillnext.com</a></p>
        
      </div>
      <div className="footer-bottom">
        <p>© Quillnext, {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
